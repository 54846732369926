import useSWR from 'swr'
import { useContext } from 'react'
import { httpClientFetcher } from 'core/api/httpClientFetcher'
import { FilterContext } from 'core/contexts/FilterContext'

const API_URL = new URL(process.env.REACT_APP_BACKEND_URL ?? '')
function makeDateConsumableForApi(date: Date): string {
  return encodeURI(date.toISOString()).split('.')[0]
}

/** This function works as {@link useApi}, but applies filters from {@link FilterContext} */
export function useFilteredApi<T = any>(
  /** f.e `/api/results` */ apiRoute: string,
  queryParams: {
    page?: number
    pageSize?: number
  } = {}
) {
  const { filters } = useContext(FilterContext)

  const finalQueryParams: Record<string, string> = {
    fromTime: makeDateConsumableForApi(filters.dateFrom),
    toTime: makeDateConsumableForApi(filters.dateTo),
  }
  if (queryParams.page) finalQueryParams['page'] = queryParams.page.toString()
  if (queryParams.pageSize) finalQueryParams['size'] = queryParams.pageSize.toString()
  if (filters.symbols.length > 0) finalQueryParams['instrumentAliases'] = filters.symbols.join(',')
  if (filters.journal !== null) finalQueryParams['journalIds'] = filters.journal

  return useApi<T>(apiRoute, { queryParams: finalQueryParams })
}

export function useApi<T = any>(
  /** e.g. `/api/results` */ apiRoute: string,
  // queryParams: Record<string, string> = {},
  { refreshInterval = 1000 * 20, queryParams = {} as Record<string, string> } = {}
) {
  const url = new URL(apiRoute, localStorage.getItem('API_URL') ?? API_URL)
  if (API_URL.toString() === '') throw new Error('API_URL is not set')

  Object.entries(queryParams).forEach(([key, value]) => url.searchParams.set(key, value))

  return useSWR<T>(url.toString(), httpClientFetcher, {
    refreshInterval,
  })
}
