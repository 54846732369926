import './TradeInfoModal.scss'
import { TradeInfoGrid } from './components/TradeInfoGrid'
import { TradeInfoModalContextProvider } from './TradeInfoModalContext'
import { TradeInfoMediaSection } from './components/TradeInfoMediaSection/TradeInfoMediaSection'
import { TradeInfoNoteTextSection } from './components/TradeInfoNoteTextSection/TradeInfoNoteTextSection'
import ModalWindowLayout from 'shared/ModalWindowLayout'
import { Button } from 'shared/Button'
import { useMemo } from 'react'
import { useUserInfo } from 'core/api/common'
import { useSelectedJournal } from 'pages/HomePage/components/Filters/useSelectedJournal'

type TradeInfoDialogProps = {
  onClose: () => void
}
function TradeInfoModal({ onClose }: Readonly<TradeInfoDialogProps>) {
  const userInfo = useUserInfo()
  const selectedJournal = useSelectedJournal()
  const noteMediaEditable = useMemo(() => {
    if (!userInfo.data) return false
    if (selectedJournal === null) return true
    return userInfo.data.userEmail === selectedJournal.owner.userEmail
  }, [selectedJournal, userInfo.data])

  return (
    <TradeInfoModalContextProvider onClose={onClose}>
      <ModalWindowLayout className="TradeInfoModal">
        <ModalWindowLayout.Header onClose={() => onClose()}>Trade info</ModalWindowLayout.Header>
        <ModalWindowLayout.Content className="content">
          <TradeInfoGrid />
          {noteMediaEditable && (
            <>
              <TradeInfoNoteTextSection />
              <TradeInfoMediaSection />
            </>
          )}
        </ModalWindowLayout.Content>
        <ModalWindowLayout.Footer>
          <Button onClick={onClose}>OK</Button>
        </ModalWindowLayout.Footer>
      </ModalWindowLayout>
    </TradeInfoModalContextProvider>
  )
}

export default TradeInfoModal
