import { JournalInfo } from 'core/types'
import { FilterSerializedState, FilterState } from './models'
import { LS_DASHBOARD_FILTERS, MS_IN_HOUR } from 'core/constants'
import { Dispatch, SetStateAction } from 'react'
import { handleSearchParam } from 'core/utils'
import { add } from 'date-fns'

const FILTER_STATE_DEFAULT: FilterState = (() => {
  return {
    dateFrom: roundDateToHours(add(new Date(), { days: -30 })),
    dateTo: roundDateToHours(add(new Date(), { days: 30 })),
    symbols: [],
    journal: null,
  }

  function roundDateToHours(date: Date) {
    return new Date(date.getTime() - (date.getTime() % MS_IN_HOUR))
  }
})()

export function getInitialFilters() {
  const savedFilters: FilterSerializedState | null = JSON.parse(
    localStorage.getItem(LS_DASHBOARD_FILTERS) ?? 'null'
  )
  if (savedFilters === null) {
    return FILTER_STATE_DEFAULT
  }

  return {
    dateFrom: new Date(savedFilters.dateFrom),
    dateTo: new Date(savedFilters.dateTo),
    symbols: savedFilters.symbols,
    journal: savedFilters.journalId || null,
  }
}

/** Calculates journal to be set in filters based on search params. If returns undefined - search params are invalid */
export function getJournalToSet(
  journals: JournalInfo[],
  journalId: string | null,
  namespaceCode: string | null
): JournalInfo | undefined {
  if (journalId === null && namespaceCode === null) return

  const journalsWithId = journalId ? journals.filter((j) => j.id === journalId) : []
  const journalsWithNamespace = namespaceCode
    ? journals.filter((j) => j.namespaceCode === namespaceCode)
    : []

  if (onlyJournalIdOk() === false) return
  if (onlyJournalTypeOk() === false) return
  if (bothIdAndTypeOk() === false) return

  if (journalsWithId[0]) return journalsWithId[0]
  if (journalsWithNamespace[0]) return journalsWithNamespace[0]
  return void 0

  // Helper functions.
  // false - means that the condition is not met, interrupt;
  // undefined - means that conditions does not make sense here - skip

  function onlyJournalIdOk() {
    if (!(journalId !== null && namespaceCode === null)) return 'SKIP'
    if (journalsWithId === null || journalsWithId.length !== 1) return false
    return true
  }

  function onlyJournalTypeOk() {
    if (!(journalId === null && namespaceCode !== null)) return 'SKIP'
    if (journalsWithNamespace.length !== 1) return false
    return true
  }

  function bothIdAndTypeOk() {
    if (!(journalId !== null && namespaceCode !== null)) return 'SKIP'
    if (journalsWithId.length !== 1 || journalsWithId[0].namespaceCode !== namespaceCode)
      return false
    return true
  }
}

export function handleSearchParamsForFilters(
  searchParams: URLSearchParams,
  updateFilters: Dispatch<SetStateAction<FilterState>>
) {
  let newSearchParams: URLSearchParams

  newSearchParams = handleSearchParam(['symbols'], searchParams, ([symbols]) => {
    const symbolsQueryParsed = symbols?.split(',')
    if (!symbolsQueryParsed) return
    updateFilters((prevFilters) => ({ ...prevFilters, symbols: symbolsQueryParsed }))
  })

  newSearchParams = handleSearchParam(['date_from'], newSearchParams, ([dateFrom, dateTo]) => {
    if (!dateFrom) return
    try {
      const dateFromObj = new Date(dateFrom)
      updateFilters((prevFilters) => ({ ...prevFilters, dateFrom: dateFromObj }))
    } catch (e) {}
  })

  newSearchParams = handleSearchParam(['date_to'], newSearchParams, ([dateTo]) => {
    if (!dateTo) return
    try {
      const dateToObj = new Date(dateTo)
      updateFilters((prevFilters) => ({ ...prevFilters, dateTo: dateToObj }))
    } catch (e) {}
  })

  return newSearchParams
}

export function handleSearchParamsForJournal(
  searchParams: URLSearchParams,
  journals: JournalInfo[],
  updateFilters: Dispatch<SetStateAction<FilterState>>
) {
  let newSearchParams: URLSearchParams

  newSearchParams = handleSearchParam(
    ['journal_id', 'namespace_code'],
    searchParams,
    ([journalId, namespaceCode]) => {
      if (!journals) return
      const journal = getJournalToSet(journals, journalId, namespaceCode)
      if (!journal) return
      updateFilters((v) => ({ ...v, journal: journal.id }))
    }
  )

  return newSearchParams
}
