import { useJournalsDetailed } from 'core/api/journals'
import { useJournalsApi } from 'core/contexts/JournalsApiContext/JournalsApiContext'
import { JournalDetailedInfo } from 'core/types'
import { useEffect } from 'react'
import Table from 'shared/Table'
import { useJournalSorting } from '../useJournalSorting'
import { JOURNALS_TYPE_NAME_MAP } from 'core/journalsNameMap'
import TextDate from 'shared/TextDate'
import { useNavigate } from 'react-router-dom'
import { IconsSrc } from 'core/assets'
import TextMoney from 'shared/TextMoney'
import Menu from 'shared/Menu'
import { Button } from 'shared/Button'

export function MyJournalsTable() {
  const { openDeleteDialog, openEditDialog, openShareDialog } = useJournalsApi()
  const journalsDetailed = useJournalsDetailed()
  const { sortedItems, sortingKey, setItems, direction, sortItems } = useJournalSorting()
  const navigate = useNavigate()

  useEffect(() => {
    if (!journalsDetailed.data) return setItems([])
    setItems(journalsDetailed.data)
  }, [journalsDetailed.data, setItems])

  return (
    <Table>
      <Table.Header>
        <Table.HeaderCell<JournalDetailedInfo>
          sortableKey="journalName"
          sortableDirection={direction}
          currentSortableKey={sortingKey}
          onSort={(key) => sortItems(key)}
        >
          Name
        </Table.HeaderCell>
        <Table.HeaderCell<JournalDetailedInfo>
          sortableKey="journalType"
          currentSortableKey={sortingKey}
          sortableDirection={direction}
          onSort={(key) => sortItems(key)}
        >
          Type
        </Table.HeaderCell>
        <Table.HeaderCell<JournalDetailedInfo>
          sortableKey="creationDate"
          sortableDirection={direction}
          currentSortableKey={sortingKey}
          onSort={(key) => sortItems(key)}
        >
          Creation date
        </Table.HeaderCell>
        <Table.HeaderCell<JournalDetailedInfo>
          sortableKey="totalTrades"
          sortableDirection={direction}
          currentSortableKey={sortingKey}
          onSort={(key) => sortItems(key)}
        >
          Trades
        </Table.HeaderCell>
        <Table.HeaderCell<JournalDetailedInfo>
          sortableKey="totalPnl"
          sortableDirection={direction}
          currentSortableKey={sortingKey}
          onSort={(key) => sortItems(key)}
        >
          P&L
        </Table.HeaderCell>
        <Table.HeaderCell<JournalDetailedInfo>
          sortableKey="maxDrawdown"
          sortableDirection={direction}
          currentSortableKey={sortingKey}
          onSort={(key) => sortItems(key)}
        >
          Max drawdown
        </Table.HeaderCell>
        <Table.HeaderCell<JournalDetailedInfo>
          sortableKey="sharedWith"
          sortableDirection={direction}
          currentSortableKey={sortingKey}
          onSort={(key) => sortItems(key)}
        >
          Sharing
        </Table.HeaderCell>
        <Table.HeaderCell.Empty />
      </Table.Header>
      <Table.Body>
        {sortedItems.map((journal) => (
          <Table.Row
            key={journal.id}
            onClick={() => navigate('/home/dashboard?journal_id=' + journal.id)}
          >
            <Table.Cell>{journal.journalName}</Table.Cell>
            <Table.Cell>
              {JOURNALS_TYPE_NAME_MAP[journal.journalType] ?? journal.journalType}
            </Table.Cell>
            <Table.Cell>
              {journal.creationDate ? <TextDate date={new Date(journal.creationDate)} /> : '-'}
            </Table.Cell>
            <Table.Cell>{journal.totalTrades}</Table.Cell>
            <Table.Cell>
              <TextMoney value={journal.totalPnl} colored />
            </Table.Cell>
            <Table.Cell>
              <TextMoney value={journal.maxDrawdown} colored />
            </Table.Cell>
            <Table.Cell className="shared-with">
              <div className={journal.sharedWith.length === 0 ? 'empty' : ''}>
                <img src={IconsSrc.Users} alt="Users shared with" /> {journal.sharedWith.length}
              </div>
            </Table.Cell>

            <Table.Cell.Menu>
              <Menu
                trigger={
                  <Button appearance="text" square={true} size="small">
                    <img src={IconsSrc.DotsVertical} alt="Menu" />
                  </Button>
                }
              >
                <Menu.Item onClick={() => openEditDialog(journal.id)}>
                  <img src={IconsSrc.Edit} alt="Edit" width={20} height={20} /> Edit
                </Menu.Item>
                <Menu.Item onClick={() => openShareDialog(journal.id)}>
                  <img src={IconsSrc.Share} alt="Edit" width={20} height={20} /> Share
                </Menu.Item>
                {journal.typeEditable === true && (
                  <Menu.Item onClick={() => openDeleteDialog(journal.id)}>
                    <img src={IconsSrc.Trash} alt="Delete" width={20} height={20} /> Remove
                  </Menu.Item>
                )}
              </Menu>
            </Table.Cell.Menu>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}
