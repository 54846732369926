import { API_URL } from 'core/constants'
import httpClient from './httpClient'
import useSWR from 'swr'
import { httpClientFetcher } from './httpClientFetcher'
import { MediaItem, Note } from 'core/types'
import { AxiosProgressEvent } from 'axios'

// CRUD operations for trade notes
export const createNote = async (tradeId: string) => {
  return httpClient.post<{ result: Note }>(API_URL + `/api/dashboard/notes/trade/${tradeId}`)
}

// export const addNote = async (noteText: string, tradeId: string) => {
//   return httpClient.post(API_URL + `/api/dashboard/trades/${tradeId}/note`, { noteText })
// }

export const editNote = async (noteText: string, noteId: string) => {
  return httpClient.put(API_URL + `/api/dashboard/notes/${noteId}`, { noteText })
}

export const deleteNote = async (noteId: string) => {
  return httpClient.delete<void>(API_URL + `/api/dashboard/notes/${noteId}`)
}

// CRUD operations for trade notes media
export const addMediaToNote = async (
  noteId: string,
  media: File,
  onUploadProgress?: (event: AxiosProgressEvent) => void
): Promise<any> => {
  const formData = new FormData()
  formData.append('file', media)
  return httpClient.post(API_URL + `/api/dashboard/notes/${noteId}/media`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress,
  })
}

const getMediaForNoteUrl = (noteId: string) => API_URL + `/api/dashboard/notes/${noteId}/media`
export const getMediaForNote = async (noteId: string) => {
  return httpClient.get(getMediaForNoteUrl(noteId))
}
export const useMediaForNote = (noteId?: string | null) => {
  return useSWR(
    noteId ? getMediaForNoteUrl(noteId) : null,
    httpClientFetcher<{ result: MediaItem[] }>
  )
}

export const deleteMediaForNote = async (noteId: string, mediaKey: string) => {
  return httpClient.delete(
    API_URL + `/api/dashboard/notes/${noteId}/media?key=${encodeURI(mediaKey)}`
  )
}
