import { PagingParams, SortingParams, useTrades } from 'core/api/dashboard'
import { useFilters } from 'core/contexts/FilterContext'
import { useEffect } from 'react'
import { TradeItem } from 'core/types'

export function useFilteredTrades(pagingSortingParams: PagingParams & SortingParams<TradeItem>) {
  const { filters } = useFilters()
  useEffect(() => console.log('pagingSortingParams', pagingSortingParams), [pagingSortingParams])
  return useTrades(filters, pagingSortingParams)
}
