import { API_URL } from 'core/constants'
import httpClient from './httpClient'
import { AxiosProgressEvent } from 'axios'
import {
  JournalDetailedInfo,
  JournalInfo,
  PnlItem,
  TradesResponse,
  TradingStatistics,
} from 'core/types'
import useSWR from 'swr'

const stringifySearchParams = (searchParams?: Record<string, string>) => {
  return searchParams ? '?' + new URLSearchParams(searchParams).toString() : ''
}

type FiltersQueryParams = {
  fromTime?: string
  toTime?: string
  instrumentAliases?: string
  journalIds?: string
}
type TradeGetAllSearchParams = {
  page?: string
  size?: string
  sort?: string
} & FiltersQueryParams

type BaseUrlFactory = (...args: any[]) => string
type BaseRequest = (...args: any[]) => Promise<any>
function makeRequest<T extends BaseUrlFactory, F extends BaseRequest>(params: {
  url: T
  request: (url: T) => F
}) {
  const result: any = params.request(params.url)
  result.getUrl = params.url
  return result as F & { getUrl: T }
}

const api = {
  trade: {
    getAll: makeRequest({
      url: (searchParams: TradeGetAllSearchParams) =>
        API_URL + '/api/dashboard/trades' + stringifySearchParams(searchParams),
      request: (getUrl) => async (searchParams: TradeGetAllSearchParams) =>
        httpClient.get<TradesResponse>(getUrl(searchParams)),
    }),
    exportAll: {
      csv: makeRequest({
        url: (searchParams: FiltersQueryParams) =>
          API_URL + '/api/dashboard/trades/csv' + stringifySearchParams(searchParams),
        request: (getUrl) => async (searchParams: FiltersQueryParams) =>
          httpClient.get(getUrl(searchParams), { responseType: 'blob' }),
      }),
      excel: makeRequest({
        url: (searchParams: FiltersQueryParams) =>
          API_URL + '/api/dashboard/trades/excel' + stringifySearchParams(searchParams),
        request: (getUrl) => async (searchParams: FiltersQueryParams) =>
          httpClient.get<Blob>(getUrl(searchParams), { responseType: 'blob' }),
      }),
    },
    note: {
      add: makeRequest({
        url: (tradeId: string) => API_URL + `/api/dashboard/trades/${tradeId}/note`,
        request: (getUrl) => async (noteText: string, tradeId: string) =>
          httpClient.post(getUrl(tradeId), { noteText }),
      }),
      edit: makeRequest({
        url: (tradeNoteId: string) => API_URL + `/api/dashboard/trades/note/${tradeNoteId}`,
        request: (getUrl) => async (noteText: string, tradeNoteId: string) =>
          httpClient.put(getUrl(tradeNoteId), { noteText }),
      }),
      delete: makeRequest({
        url: (tradeNoteId: string) => API_URL + `/api/dashboard/trades/note/${tradeNoteId}`,
        request: (getUrl) => async (tradeNoteId: string) =>
          httpClient.delete<void>(getUrl(tradeNoteId)),
      }),
    },
    media: {
      add: makeRequest({
        url: (tradeId: string) => API_URL + `/api/dashboard/trades/${tradeId}/media`,
        request:
          (getUrl) =>
          async (
            tradeId: string,
            media: File,
            onUploadProgress?: (event: AxiosProgressEvent) => void
          ) => {
            const formData = new FormData()
            formData.append('file', media)
            return httpClient.post(getUrl(tradeId), formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
              onUploadProgress,
            })
          },
      }),
      get: makeRequest({
        url: (tradeId: string) => API_URL + `/api/dashboard/trades/${tradeId}/media`,
        request: (getUrl) => async (tradeId: string) => httpClient.get(getUrl(tradeId)),
      }),
      delete: makeRequest({
        url: (tradeId: string, mediaKey: string) =>
          API_URL + `/api/dashboard/trades/${tradeId}/media?key=${encodeURI(mediaKey)}`,
        request: (getUrl) => async (tradeId: string, mediaKey: string) =>
          httpClient.delete(getUrl(tradeId, mediaKey)),
      }),
    },
  },
  statistics: {
    get: makeRequest({
      url: (searchParams?: FiltersQueryParams) =>
        API_URL + '/api/dashboard/statistics' + searchParams,
      request: (getUrl) => async (searchParams?: FiltersQueryParams) =>
        httpClient.get<{ result: TradingStatistics }>(getUrl(searchParams)),
    }),
  },
  pnl: {
    get: makeRequest({
      url: (searchParams?: FiltersQueryParams) =>
        API_URL + '/api/dashboard/pnl' + stringifySearchParams(searchParams),
      request: (getUrl) => async (searchParams?: FiltersQueryParams) =>
        httpClient.get<{ result: { dataPoints: PnlItem[] } }>(getUrl(searchParams)),
    }),
  },
  journal: {
    getAll: makeRequest({
      url: () => API_URL + '/api/dashboard/journals',
      request: (getUrl) => async () => httpClient.get<{ result: JournalInfo[] }>(getUrl()),
    }),
    getDetailedAll: makeRequest({
      url: () => API_URL + '/api/dashboard/journals/detailed',
      request: (getUrl) => async () => httpClient.get(getUrl()),
    }),
    share: makeRequest({
      url: (journalId: string) => API_URL + `/api/dashboard/journals/${journalId}/shareWith`,
      request: (getUrl) => async (journalId: string, contacts: string[]) =>
        httpClient.post(getUrl(journalId), contacts),
    }),
    edit: makeRequest({
      url: (journalId: string) => API_URL + `/api/dashboard/journals/${journalId}`,
      request: (getUrl) => async (journalId: string, journal: any) =>
        httpClient.put(getUrl(journalId), journal),
    }),
    add: makeRequest({
      url: () => API_URL + '/api/dashboard/journals',
      request: (getUrl) => async (journalName: string, journalType: string) =>
        httpClient.post<{ result: JournalInfo }>(getUrl(), { journalName, journalType }),
    }),
    types: {
      getAll: makeRequest({
        url: () => API_URL + '/api/dashboard/journals/types',
        request: (getUrl) => async () => httpClient.get(getUrl()),
      }),
    },
    shared: {
      withMe: {
        getAll: makeRequest({
          url: () => API_URL + '/api/dashboard/journals/shared',
          request: (getUrl) => async () => httpClient.get<{ result: JournalInfo[] }>(getUrl()),
        }),
        getDetailedAll: makeRequest({
          url: () => API_URL + '/api/dashboard/journals/shared/detailed',
          request: (getUrl) => async () =>
            httpClient.get<{ result: JournalDetailedInfo[] }>(getUrl()),
        }),
        remove: makeRequest({
          url: (journalId: string) => API_URL + `/api/dashboard/journals/${journalId}`,
          request: (getUrl) => async (journalId: string) => httpClient.delete(getUrl(journalId)),
        }),
      },
      byMe: {
        getUsersAll: makeRequest({
          url: (journalId: string) => API_URL + `/api/dashboard/journals/${journalId}/sharedWith`,
          request: (getUrl) => async (journalId: string) =>
            httpClient.get<{ result: string[] }>(getUrl(journalId)),
        }),
        removeForUsers: makeRequest({
          url: (journalId: string, contacts: string[]) =>
            API_URL +
            `/api/dashboard/journals/${journalId}/shareWith?userEmails=${contacts.join(',')}`,
          request: (getUrl) => async (journalId: string, contacts: string[]) =>
            httpClient.delete(getUrl(journalId, contacts)),
        }),
      },
    },
  },
}

export function useSWRApiBlank<T extends BaseUrlFactory, F extends BaseRequest>(
  item: ReturnType<typeof makeRequest<T, F>>
) {
  return useSWR<ReturnType<F>>(item.getUrl(), async () => (await item()).data)
}

export default api
