import './StaticTabs.scss'
import classNames from 'classnames'
import { Link, useLocation } from 'react-router-dom'
import { Button } from 'shared/Button'

type StaticTabsProps = { children?: React.ReactNode }
function StaticTabs({ children }: StaticTabsProps) {
  return <div className="StaticTabs">{children}</div>
}

type StaticTabsTabProps = { to: string; children: React.ReactNode }
function StaticTabsTab({ to, children }: StaticTabsTabProps) {
  const location = useLocation()
  const isActive = location.pathname === to

  return (
    <Link to={to} className={classNames('StaticTabs__Tab', isActive ? 'active' : '')}>
      <Button appearance="menu-item" size="large">
        {children}
      </Button>
    </Link>
  )
}

StaticTabs.Tab = StaticTabsTab

export default StaticTabs
