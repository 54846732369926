import { useContext, useEffect, useState } from 'react'
import { appFetch } from 'core/utils'
import { API_URL } from 'core/constants'
import { TradesTableTabContext } from '../TradesTableTabContext/TradesTableTabContext'
import { ModalLayout } from 'shared/ModalLayout'
import { Button } from 'shared/Button'

type AddEditNoteDialogProps = {
  onClose: () => void
}

function AddEditNoteModal({ onClose }: Readonly<AddEditNoteDialogProps>) {
  const { selectedTrade, tradesResponse } = useContext(TradesTableTabContext)

  const [note, setNote] = useState('')
  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState<'Add' | 'Update'>('Add')

  const updateTradeNote = async (note: string) => {
    if (!selectedTrade) return
    setLoading(true)

    const requestParams = selectedTrade.note
      ? { url: `/api/dashboard/trades/note/${selectedTrade.note.id}`, method: 'PUT' }
      : { url: `/api/dashboard/trades/${selectedTrade.id}/note`, method: 'POST' }

    try {
      await appFetch(API_URL + requestParams.url, requestParams.method, { noteText: note })
      onClose()
      tradesResponse.mutate()
    } catch (error) {
      alert('Something went wrong')
    } finally {
      setLoading(false)
    }
  }

  const handleAddNote = () => {
    updateTradeNote(note)
    setNote('')
  }

  const handleCancel = () => {
    onClose()
  }

  useEffect(() => {
    if (selectedTrade === null) return
    setNote(selectedTrade.note?.noteText ?? '')
    const newAction = selectedTrade.note && selectedTrade.note.noteText !== '' ? 'Update' : 'Add'
    if (newAction !== action) setAction(newAction)
  }, [selectedTrade, action])

  return (
    <ModalLayout>
      <ModalLayout.Heading>{action} note</ModalLayout.Heading>
      <form action="">
        <ModalLayout.FormControl label="Note">
          <textarea
            tabIndex={0}
            id="note-text"
            value={note}
            style={{ height: '64px' }}
            placeholder="Type your note here..."
            onChange={(e) => setNote(e.target.value)}
          />
        </ModalLayout.FormControl>
      </form>
      <ModalLayout.Buttons>
        <Button disabled={loading} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          isLoading={loading}
          color="primary"
          disabled={selectedTrade?.note?.noteText === note && selectedTrade?.note?.noteText !== ''}
          onClick={handleAddNote}
        >
          {action}
        </Button>
      </ModalLayout.Buttons>
    </ModalLayout>
  )
}

export default AddEditNoteModal
