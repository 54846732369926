import { useQuery } from '@apollo/client'
import { useGetBarDiagramQuery } from 'core/api/widgets'
import { useFilters } from 'core/contexts/FilterContext'
import { useGlobalTimezone } from 'core/contexts/GlobalTimezoneContext'
import { BarDiagramResponse, MetricsCellConfig } from 'core/types'
import { useEffect, useMemo, useRef } from 'react'

export function useWidgetQuery(data: MetricsCellConfig) {
  const { filters } = useFilters()
  const { timeZone } = useGlobalTimezone()

  const { current: config } = useRef(data)
  const query = useGetBarDiagramQuery(filters, config, timeZone.offsetMs / 1000 / 60)
  const result = useQuery<BarDiagramResponse>(query)

  useEffect(() => {
    result.refetch()
  })
  return result
}

/** For font size 12px */
export function useYAxisWidth<T>(data: T[], getData: (item: T) => number): number {
  const { dataMax, dataMin } = useMemo(
    () => ({
      dataMax: Math.max(...data.map(getData)),
      dataMin: Math.min(...data.map(getData)),
    }),
    [data, getData]
  )
  const ABS = useMemo(() => Math.max(dataMax, Math.abs(dataMin)), [dataMax, dataMin])

  return useMemo(() => getWidth(ABS), [ABS])

  function getWidth(value: number): number {
    if (value < 100) return 60
    if (value < 1000) return 65
    if (value < 1e6) return 85
    if (value < 1e9) return 110
    if (value < 1e12) return 130
    if (value < 1e16) return 150
    return 160
  }
}
