import { JournalInfo, TradeItem, TradesResponse } from 'core/types'
import './TradesTable.scss'

import Table from 'shared/Table'
import { useContext, useMemo } from 'react'
import { useJournals } from 'core/api/journals'
import { TradesTableTabContext } from '../../TradesTableTabContext'
import { TradesTableDynamicRow } from './TradesTableDynamicRow'
import { FilterContext } from 'core/contexts/FilterContext'
import { TABLE_PAGE_SIZE } from '../../TradesTableTabContext/constants'
import { useUserInfo } from 'core/api/common'
import { useSelectedJournal } from 'pages/HomePage/components/Filters/useSelectedJournal'

type TradesTableProps = { data: TradesResponse; isLoading: boolean }
export function TradesTable({ data, isLoading }: Readonly<TradesTableProps>) {
  const { columns, setSortParams, sortParams } = useContext(TradesTableTabContext)

  const sortBy = (key: keyof TradeItem) => {
    console.log('sort key', key)
    if (sortParams.sort === key) {
      setSortParams({
        ...sortParams,
        order: sortParams.order === 'asc' ? 'desc' : 'asc',
      })
    } else {
      setSortParams({
        sort: key,
        order: 'desc',
      })
    }
  }

  const { filters } = useContext(FilterContext)
  const journals = useJournals()
  const journalsIdMap: Record<string, JournalInfo> = useMemo(() => {
    if (!journals.data) return {}
    return Object.fromEntries(journals.data.map((j) => [j.id, j]))
  }, [journals])

  const isEmpty = data?.pageInfo.totalElements === 0
  const userInfo = useUserInfo()
  const selectedJournal = useSelectedJournal()

  const showTradesActionColumn =
    selectedJournal === null || userInfo.data?.userEmail === selectedJournal?.owner.userEmail

  return (
    <Table className="TradesTable" tableNeighbor={isEmpty && <EmptyState />}>
      <Table.Header>
        {filters.journal === null && <Table.HeaderCell>Journal</Table.HeaderCell>}
        {columns.map((header) => (
          <Table.HeaderCell<TradeItem>
            sortableKey={header.sortableKey}
            currentSortableKey={sortParams.sort}
            sortableDirection={sortParams.order}
            onSort={sortBy}
            tooltipText={header.tooltip}
            isLoading={isLoading}
            key={header.sortableKey}
          >
            {header.name}
          </Table.HeaderCell>
        ))}
        <Table.HeaderCell<TradeItem>>Note</Table.HeaderCell>
        {showTradesActionColumn && <Table.HeaderCell.Empty />}
      </Table.Header>
      <Table.Body>
        {!isEmpty &&
          data.result.map((item) => (
            <TradesTableDynamicRow item={item} key={item.id} journalsIdMap={journalsIdMap} />
          ))}
      </Table.Body>
    </Table>
  )
}

function EmptyState() {
  return (
    <div className="EmptyState" style={{ height: 48 * TABLE_PAGE_SIZE + 'px' }}>
      <h2>No trades</h2>
      <p>
        It looks like there are no results that match your search criteria. Please, try another one
      </p>
    </div>
  )
}
