import { intervalToDuration } from 'date-fns'

export const formatMoney = (value: number, { valueSym = '$' } = {}): string => {
  const signSym = value >= 0 ? '' : '-'
  if (Math.abs(value) < 1e-2 && Math.abs(value) > 0) return signSym + valueSym + value.toFixed(4)
  const [big, small] = Math.abs(value).toFixed(2).split('.')

  return signSym + valueSym + addCommas(big) + '.' + small

  function addCommas(value: string): string {
    if (value.length < 3) return value
    return value
      .split('')
      .map((v, i, arr) => ((arr.length - i) % 3 === 0 && i !== 0 ? ',' + v : v))
      .join('')
  }
}

export function durationToHumanReadable(durationMs: number, { separator = ' ' } = {}): string {
  if (durationMs <= 0) return '0s'
  // const units = [
  //   { label: 'd', value: 1 * 60 * 60 * 24 },
  //   { label: 'h', value: 1 * 60 * 60 },
  //   { label: 'm', value: 1 * 60 },
  //   { label: 's', value: 1 },
  // ]

  // let remainingTime = Math.abs(durationMs)
  // const result = []

  // for (const unit of units) {
  //   const quotient = Math.floor(remainingTime / unit.value)
  //   if (quotient > 0) {
  //     result.push(`${quotient}${unit.label}`)
  //     remainingTime -= quotient * unit.value
  //   }
  // }

  // return result.join(separator)
  const duration = intervalToDuration({ start: 0, end: durationMs })
  let resultString = ''
  if (duration.days) resultString += `${duration.days}d `
  if (duration.hours) resultString += `${duration.hours}h `
  if (duration.minutes) resultString += `${duration.minutes}m `
  if (duration.seconds) resultString += `${duration.seconds}s `
  return resultString.trim()
}

/**
 * Reduce number of elements in array in most simple way
 *
 * `[1,2,3,4,5,6,7,8,9,10] -> [1,3,5,7,9,10]`
 **/
export function reduceArrayTo<T>(
  /** Target array */
  arr: T[],
  /** Number of elements should be reduced to */
  targetLength: number
): T[] {
  if (arr.length < targetLength) return arr

  const result = []
  const ratio = arr.length / targetLength
  for (let i = 0; i < targetLength; i++) result.push(arr[Math.floor(i * ratio)])

  /* Last element in result should match last element on input*/
  result[result.length - 1] = arr[arr.length - 1]
  return result
}

/**
 * Parse params, feed to callback & remove them from from result & add params from return
 *
 * @example
 *  ```ts
 *  let searchParams = new URLSearchParams('a=1,b=2,c=3')
 *  searchParams = handleSearchParam(
 *    ['a', 'b'], // remove a and b
 *    searchParams,
 *    ([a, b]) => { a: 4, e: 5 } // add a and e
 *  )
 *  console.log(searchParams.toString()) // 'a=4,c=3,e=5'
 *
 *  // or
 * searchParams = handleSearchParam(
 *   ['a', 'b'], // remove a and b
 *  searchParams,
 * ([a, b]) => void 0 // add nothing
 * )
 * ```
 */
export function handleSearchParam(
  paramsKeys: string[],
  searchParams: URLSearchParams,
  handler: (paramKeyValues: (string | null)[]) => Record<string, string> | void
): URLSearchParams {
  const newSearchParams = new URLSearchParams(searchParams.toString())

  const paramKeyValues = paramsKeys.map((paramKey) => newSearchParams.get(paramKey))
  paramsKeys.forEach((paramKey) => newSearchParams.delete(paramKey))
  const outputParamsMap = handler(paramKeyValues)
  if (outputParamsMap) {
    Object.entries(outputParamsMap).forEach(([key, value]) => {
      if (value != null) newSearchParams.set(key, value)
    })
  }
  return newSearchParams
}

export function downloadBlob(blob: Blob, filename: string) {
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  a.click()
}
