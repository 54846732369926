import axios from 'axios'
import { LS_ACCESS_TOKEN } from 'core/constants'

const httpClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
})

httpClient.interceptors.request.use((config) => {
  const token = localStorage.getItem(LS_ACCESS_TOKEN)
  config.headers.Authorization = token ? `Bearer ${token}` : undefined
  return config
})

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('token')
      localStorage.removeItem(LS_ACCESS_TOKEN)
      if (window.location.pathname !== '/login' && window.location.pathname !== '/signup') {
        window.location.reload()
      }
    }
    return Promise.reject(error)
  }
)

export default httpClient
