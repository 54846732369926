import { usePnl, useStatistics } from 'core/api/dashboard'
import { FilterContext } from 'core/contexts/FilterContext'
import { useContext } from 'react'

export function useFilteredStatistics() {
  const { filters } = useContext(FilterContext)
  return useStatistics(filters)
}

export function useFilteredPnl() {
  const { filters } = useContext(FilterContext)
  return usePnl(filters)
}
