import { PnlItem, TradeItem, TradesResponse, TradingStatistics } from 'core/types'
import { API_URL } from 'core/constants'
import useSWR from 'swr'
import { useMemo } from 'react'
import { httpClientFetcher } from './httpClientFetcher'
import { makeDateConsumableForApi } from 'core/utils'
import httpClient from './httpClient'
import { FilterState } from 'core/contexts/FilterContext'

/* TO DO: move to somewhere else */
export type PagingParams = {
  page: number
  size: number
}
export type SortingParams<T extends object> = {
  sort: keyof T
  order: 'asc' | 'desc'
}

export type DashboardTradesTableSearchParams = FilterState & SortingParams<TradeItem> & PagingParams

const calcSearchParams = (
  filters: FilterState,
  pagingSortingInfo?: PagingParams & SortingParams<TradeItem>
): string => {
  const searchParams = new URLSearchParams()
  searchParams.set('fromTime', makeDateConsumableForApi(filters.dateFrom))
  searchParams.set('toTime', makeDateConsumableForApi(filters.dateTo))
  if (filters.symbols.length > 0) searchParams.set('instrumentAliases', filters.symbols.join(','))
  if (filters.journal) searchParams.set('journalIds', filters.journal)

  if (pagingSortingInfo) {
    searchParams.set('page', pagingSortingInfo.page.toString())
    searchParams.set('size', pagingSortingInfo.size.toString())
    searchParams.set('sort', [pagingSortingInfo.sort, pagingSortingInfo.order].join(','))
  }
  return searchParams.toString()
}
const useCalculatedParams = (
  filters: FilterState,
  pagingSortingInfo?: PagingParams & SortingParams<TradeItem>
) =>
  useMemo(() => {
    // console.log('recalc search params')
    return calcSearchParams(filters, pagingSortingInfo)
  }, [filters, pagingSortingInfo])

export const usePnl = (filters: FilterState) => {
  const searchParams = useCalculatedParams(filters)
  const url = API_URL + '/api/dashboard/pnl?' + searchParams
  return useSWR(url, httpClientFetcher<{ result: { dataPoints: PnlItem[] } }>)
}

export const useStatistics = (filters: FilterState) => {
  const searchParams = useCalculatedParams(filters)
  const url = API_URL + '/api/dashboard/statistics?' + searchParams
  return useSWR(url, httpClientFetcher<{ result: TradingStatistics }>)
}

export const useTrades = (
  filters: FilterState,
  pagingSortingInfo: PagingParams & SortingParams<TradeItem>
) => {
  const searchParams = useCalculatedParams(filters, pagingSortingInfo)
  // console.trace('searchParams', searchParams.split('&').join('\n'))
  const baseUrl = API_URL + '/api/dashboard/trades'
  const url = baseUrl + '?' + searchParams

  //   const result = useSWR(baseUrl, async () => (await apiClient.get<TradesResponse>(url)).data)
  //   useEffect(() => {
  //     result.mutate()
  //   }, [result.data, pagingSortingInfo, filters])
  //   return result

  return useSWR(url, async () => (await httpClient.get<TradesResponse>(url)).data)
}

// export const getExportTradesToCsv = async (filters: FilterState) => {
//   const searchParams = calcSearchParams(filters)
//   const url = API_URL + '/api/dashboard/trades/csv?' + searchParams
//   return httpClient.get(url, { responseType: 'blob' })
// }

// export const getExportTradesToExcel = async (filters: FilterState) => {
//   const searchParams = calcSearchParams(filters)
//   const url = API_URL + '/api/dashboard/trades/excel?' + searchParams
//   return httpClient.get(url, { responseType: 'blob' })
// }
