import { AllHTMLAttributes, useContext, useMemo, useState } from 'react'
import './TradeInfoNoteTextSection.scss'
import { createNote, editNote } from 'core/api/trades'
import { TradeInfoModalContext } from '../../TradeInfoModalContext'
import { TradesTableTabContext } from 'pages/HomePage/tabs/TradesTableTab/TradesTableTabContext'
import { useCallAndReport } from 'core/hooks/useDoAndReport'
import { Button } from 'shared/Button'
import Icon from 'shared/Icon'

export function TradeInfoNoteTextSection() {
  const { editMode } = useContext(TradeInfoModalContext)
  return (
    <div className="TradeInfoNoteTextSection text-body-bold">
      <div className="heading text-normal-bold">Note</div>
      {editMode ? <TradeInfoNoteForm /> : <NoteTextPreview />}
    </div>
  )
}

export function NoteTextPreview() {
  const { tradeItem, setEditMode } = useContext(TradeInfoModalContext)
  const noteText: string = tradeItem.note?.noteText ?? ''

  return (
    <div className="NoteTextPreview" onClick={() => setEditMode(true)}>
      <div className="text">
        {noteText ? noteText : <div className="placeholder">Click here to enter note text</div>}
      </div>
      <div className="side-panel">
        <Icon name="Edit" />
      </div>
    </div>
  )
}

type TradeInfoDialogNoteFormProps = AllHTMLAttributes<HTMLDivElement>
export function TradeInfoNoteForm(props: Readonly<TradeInfoDialogNoteFormProps>) {
  const { setEditMode } = useContext(TradeInfoModalContext)
  const { value, setValue, isLoading, submitNote } = useNoteValue(() => setEditMode(false))

  return (
    <div className="TradeInfoNoteForm" {...props}>
      <textarea
        className={'input'}
        placeholder="Type your note here..."
        rows={7}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />

      <div className="buttons">
        <Button appearance="outline" size="small" onClick={() => setEditMode(false)}>
          Cancel
        </Button>
        <Button color="primary" size="small" isLoading={isLoading} onClick={() => submitNote()}>
          Save
        </Button>
      </div>
    </div>
  )
}

function useNoteValue(onClose: () => void) {
  const { tradesResponse } = useContext(TradesTableTabContext)
  const { tradeItem } = useContext(TradeInfoModalContext)
  const [value, setValue] = useState(tradeItem.note?.noteText ?? '')

  const [initialValue] = useState(tradeItem.note)
  const noteWasChanged = useMemo(() => {
    if (value === '' && initialValue === null) return false
    return value !== initialValue?.noteText
  }, [value, initialValue])

  const { callAndReport, isLoading } = useCallAndReport()
  const submitNote = async () => {
    if (!noteWasChanged) return onClose()
    await callAndReport(
      async () => {
        let noteId: string
        if (tradeItem.note) {
          noteId = tradeItem.note.id
        } else {
          const {
            data: { result: newNote },
          } = await createNote(tradeItem.id)
          noteId = newNote.id
        }
        await editNote(value, noteId)
        await tradesResponse.mutate()
        onClose()
      },
      {
        OK: 'Note updated',
        DEFAULT_ERR: 'Failed to update note',
      }
    )
  }

  return { value, setValue, isLoading, submitNote }
}
