import App from 'App'
import { Home } from 'pages/HomePage/HomePage'
import DashboardTab from 'pages/HomePage/tabs/DashboardTab'
import { TradesTableTab } from 'pages/HomePage/tabs/TradesTableTab/TradesTableTab'
import { Login } from 'pages/Login/Login'
import { ManageJournalsPage } from 'pages/ManageJournalsPage/ManageJournalsPage'
import MetricsPage from 'pages/MetricsPage'
import { SignUp } from 'pages/SignUp/SignUp'
import { Navigate, createBrowserRouter } from 'react-router-dom'

export const appRouter = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Navigate to="/home" replace />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/signup',
        element: <SignUp />,
      },
      {
        path: '/manage-journals',
        element: <ManageJournalsPage />,
      },
      {
        path: '/metrics',
        element: <MetricsPage />,
      },
      {
        path: '/home',
        element: <Home />,
        children: [
          {
            path: '/home',
            element: <Navigate to="/home/dashboard" replace />,
          },
          {
            path: '/home/dashboard',
            element: <DashboardTab />,
          },
          {
            path: '/home/trades-table',
            element: <TradesTableTab />,
          },
        ],
      },
    ],
  },
])
