import { useCallback, useContext, useEffect, useMemo } from 'react'
import { FilterSerializedState, FilterState } from './models'
import { LS_DASHBOARD_FILTERS } from 'core/constants'
import { FilterContext } from './FilterContext'
import { handleSearchParamsForFilters, handleSearchParamsForJournal } from './utils'
import { JournalInfo } from 'core/types'

export function useFilters() {
  const { filters, setFilters } = useContext(FilterContext)

  const setFiltersBySearchParams = useCallback(
    (searchParams: URLSearchParams) => handleSearchParamsForFilters(searchParams, setFilters),
    [setFilters]
  )

  const setJournalBySearchParams = useCallback(
    (searchParams: URLSearchParams, journals: JournalInfo[]) =>
      handleSearchParamsForJournal(searchParams, journals, setFilters),
    [setFilters]
  )

  const filtersSearchParams = useMemo(() => {
    const searchParams = new URLSearchParams()
    searchParams.set('dateFrom', filters.dateFrom.toISOString())
    searchParams.set('dateTo', filters.dateTo.toISOString())
    if (filters.symbols.length > 0) searchParams.set('symbols', filters.symbols.join(','))
    if (filters.journal) {
      searchParams.set('journal_id', filters.journal)
    }
    return searchParams
  }, [filters])

  return {
    filters,
    setFilters,
    setFiltersBySearchParams,
    setJournalBySearchParams,
    filtersSearchParams,
  }
}

export function useFilterStateLocalStorageSync(filters: FilterState) {
  const serializedFilters = useMemo(
    () =>
      ({
        dateFrom: filters.dateFrom.toISOString(),
        dateTo: filters.dateTo.toISOString(),
        symbols: filters.symbols,
        journalId: filters.journal ?? '',
      } satisfies FilterSerializedState),
    [filters]
  )

  useEffect(() => {
    if (
      new Date(serializedFilters.dateFrom).getTime() === 0 &&
      new Date(serializedFilters.dateTo).getTime() === 0
    ) {
      return
    }
    localStorage.setItem(LS_DASHBOARD_FILTERS, JSON.stringify(serializedFilters))
  }, [serializedFilters])
}
