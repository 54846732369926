import { useEffect, useState } from 'react'
import './Login.scss'
import logo from 'assets/trading-journal-logo.svg'
import { useNavigate } from 'react-router-dom'
import { API_URL, LS_ACCESS_TOKEN, LS_RESTORE_PATH } from 'core/constants'
import { HttpError, appFetch } from 'core/utils'
import { ModalLayout } from 'shared/ModalLayout'
import { Button } from 'shared/Button'
import { useUpdateJournals } from 'core/contexts/JournalsApiContext/hooks'
import PasswordInput from 'shared/PasswordInput'
import { useForm, useWatch } from 'react-hook-form'
import classNames from 'classnames'
import imgInfoBlue from 'assets/textures/info-blue.svg'
import LabeledDivider from 'shared/LabeledDivider'

type LoginForm = {
  username: string
  password: string
}
type LoginError = 'WRONG_CRED' | 'UNKNOWN' | null

export function Login() {
  const {
    register,
    handleSubmit,
    trigger,
    control,
    formState: { errors, isValid, isSubmitted },
  } = useForm<LoginForm>()

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const updateJournals = useUpdateJournals()

  const [customError, setCustomError] = useState<LoginError>(null)
  const allFields = useWatch({ control })
  useEffect(() => {
    if (customError === null) return
    setCustomError(null)
    setTimeout(() => trigger())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFields, trigger])

  const onSubmit = handleSubmit(async (data) => {
    const handleError = (err: unknown): LoginError => {
      if (err instanceof HttpError) {
        if (err.status === 401) return 'WRONG_CRED'
        if (err.message && err.message !== '') alert(err.message)
      }
      return 'UNKNOWN'
    }

    try {
      setLoading(true)
      const response = await appFetch(API_URL + '/api/dashboard/login', 'POST', data)
      if (!('access_token' in response)) throw new Error('No access token in response!')

      localStorage.setItem(LS_ACCESS_TOKEN, response.access_token)

      const restorePath = localStorage.getItem(LS_RESTORE_PATH)
      navigate(restorePath ?? '/')
      localStorage.removeItem(LS_RESTORE_PATH)
      updateJournals()
    } catch (err) {
      setCustomError(handleError(err))
      console.error(err)
      setTimeout(() => trigger())
    } finally {
      setLoading(false)
    }
  })

  return (
    <div className="Login card-page">
      <ModalLayout>
        <img className="logo" src={logo} alt="Logo" />
        <div className="info-box">
          <div className="ico">
            <img src={imgInfoBlue} alt="Info" />
          </div>
          <div className="text">Use credentials from Bookmap.com account</div>
        </div>
        <form onSubmit={onSubmit}>
          <ModalLayout.FormControl label="Email" className="form-control">
            <input
              type="text"
              id="login"
              placeholder="Enter email"
              {...register('username', {
                required: 'This field is required',
                validate: {
                  login: () => customError !== 'WRONG_CRED' || 'EMPTY',
                  unknown: () => customError !== 'UNKNOWN' || 'EMPTY',
                  trimCheck: (v) => v.trim() !== '' || 'This field is required',
                },
              })}
              className={classNames({ error: errors.username })}
            />
            {errors.username && errors.username.message !== 'EMPTY' && (
              <ModalLayout.FormControl.Error>
                {errors.username.message}
              </ModalLayout.FormControl.Error>
            )}
          </ModalLayout.FormControl>
          <ModalLayout.FormControl label="Password" className="form-control">
            <PasswordInput
              type="password"
              id="password"
              placeholder="Enter password"
              {...register('password', {
                required: 'This field is required',
                validate: {
                  login: () => customError !== 'WRONG_CRED' || 'Enter correct email and password',
                  unknown: () => customError !== 'UNKNOWN' || 'Unknown error',
                  trimCheck: (v) => v.trim() !== '' || 'This field is required',
                },
              })}
              className={classNames({ error: errors.password })}
            />
            {errors.password && (
              <ModalLayout.FormControl.Error>
                {errors.password.message}
              </ModalLayout.FormControl.Error>
            )}
            <div className="support">
              <span>Password not working?</span>
              <a href="https://bookmap.com/support/" target="_blank" rel="noreferrer">
                <Button appearance="link" color="primary" type="button">
                  Contact support
                </Button>
              </a>
            </div>
          </ModalLayout.FormControl>
          <Button
            color="primary"
            isLoading={loading}
            type="submit"
            disabled={isSubmitted && !isValid}
          >
            Login
          </Button>
        </form>

        <div className="alternative-section">
          <LabeledDivider label="Don't have an account?" />
          <Button onClick={() => navigate('/signup')}>Register with Bookmap credentials</Button>
        </div>
      </ModalLayout>
    </div>
  )
}
