import './TradeInfoMediaSection.scss'
import { addMediaToNote, createNote, deleteMediaForNote, useMediaForNote } from 'core/api/trades'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { TradeInfoModalContext } from '../../TradeInfoModalContext'
import { MediaItem, Note } from 'core/types'
import { Modal, ModalController, useModal } from 'shared/Modal'
import { useTrackImgLoading } from '../../hooks'
import classNames from 'classnames'
import SkeletonBlock from 'shared/SkeletonBlock'
import Icon from 'shared/Icon'
import { Button } from 'shared/Button'
import { useCallAndReport } from 'core/hooks/useDoAndReport'
import { ModalLayout } from 'shared/ModalLayout'
import { useNotifications } from 'shared/Notifications'
import { AxiosError } from 'axios'
import { TradesTableTabContext } from 'pages/HomePage/tabs/TradesTableTab/TradesTableTabContext'
import { useDropzone } from 'react-dropzone'

const FILE_PARAMS = {
  maxSize: 5 * 1024 * 1024,
  allowedFormats: ['image/jpeg', 'image/png'],
}

type UploadingItem = { file: File; startTimestamp: number; progress?: number | null }
type UseAttachmentUploadingConfig = {
  onMediaUpdate: () => Promise<unknown>
  onTradeUpdate: () => Promise<unknown>
}
function useAttachmentUploading({ onMediaUpdate, onTradeUpdate }: UseAttachmentUploadingConfig) {
  const { tradeItem } = useContext(TradeInfoModalContext)
  const [uploadingMap, setUploadingMap] = useState<Record<string, UploadingItem>>({})
  const uploadingList = useMemo(
    () => Object.values(uploadingMap).sort((a, b) => a.startTimestamp - b.startTimestamp),
    [uploadingMap]
  )
  const { showNotification } = useNotifications()

  const addFileToMap = useCallback(
    (file: File) => {
      if (uploadingMap[file.name]) throw new Error('File already exists in uploading map')
      setUploadingMap({
        ...uploadingMap,
        [file.name]: {
          file,
          startTimestamp: Date.now(),
          progress: undefined,
        } satisfies UploadingItem,
      })
    },
    [uploadingMap]
  )

  const updateProgress = useCallback((fileName: string, progress?: number | null) => {
    setUploadingMap((oldMap) => ({
      ...oldMap,
      [fileName]: { ...oldMap[fileName], progress } satisfies UploadingItem,
    }))
  }, [])

  const removeFileFromMap = useCallback(
    (fileName: string) => {
      const { [fileName]: _, ...rest } = uploadingMap
      setUploadingMap(rest)
    },
    [uploadingMap]
  )

  const uploadImage = async (file: File) => {
    try {
      addFileToMap(file)
      let currentNote: Note
      if (tradeItem.note) {
        currentNote = tradeItem.note
      } else {
        const response = await createNote(tradeItem.id)
        currentNote = response.data.result
      }
      await addMediaToNote(currentNote.id, file, (ev) => {
        console.log('progress', ev.progress ? ev.progress * 100 : null)
        updateProgress(file.name, ev.progress ? ev.progress * 100 : null)
      })
      if (!tradeItem.note) await onTradeUpdate()
      await onMediaUpdate()
    } catch (err) {
      console.error(err)
      if (err instanceof AxiosError && err.response?.data) {
        showNotification({
          text: err.response.data.message ?? 'Failed to upload image',
          type: 'error',
        })
      } else {
        showNotification({
          text: 'Failed to upload image',
          type: 'error',
        })
      }
    } finally {
      removeFileFromMap(file.name)
    }
  }

  return { uploadingList, uploadImage, uploadingMap }
}

export function TradeInfoMediaSection() {
  const { tradesResponse } = useContext(TradesTableTabContext)
  const { tradeItem } = useContext(TradeInfoModalContext)
  const mediaResponse = useMediaForNote(tradeItem.note?.id)
  const inputRef = useRef<HTMLInputElement>(null)
  const { showNotification } = useNotifications()

  const resetFileInput = () => {
    if (!inputRef.current) return
    inputRef.current.value = ''
  }

  const { uploadImage, uploadingList } = useAttachmentUploading({
    onMediaUpdate: mediaResponse.mutate,
    onTradeUpdate: tradesResponse.mutate,
  })

  const onDrop: (file: File[]) => void = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      console.log(acceptedFiles)
      const selectedFile = acceptedFiles[0]
      if (!selectedFile) return
      if (selectedFile.size > FILE_PARAMS.maxSize) {
        return showNotification({
          text: 'File size should be less than 5MB',
          type: 'error',
        })
      }
      if (!FILE_PARAMS.allowedFormats.includes(selectedFile.type)) {
        return showNotification({
          text: 'File should be an JPG or PNG image',
          type: 'error',
        })
      }
      resetFileInput()
      uploadImage(selectedFile)
    },
    [showNotification, uploadImage]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  })

  return (
    <div className="TradeInfoMediaSection">
      <div className="heading text-normal-bold">Attachments</div>
      <div className="attachments-section__wrapper">
        <div
          className={classNames('attachments-section', isDragActive ? 'dragged-over' : '')}
          {...getRootProps()}
        >
          <div className="buttons">
            Drop file here or{' '}
            <Button
              appearance="link"
              color="primary"
              size="small"
              onClick={() => inputRef.current?.click()}
            >
              browse
            </Button>
          </div>
          {uploadingList.length > 0 && (
            <div className="loaders">
              {uploadingList.map((uploadingItem) => (
                <AttachmentLoader
                  key={uploadingItem.file?.name ?? 'N/A'}
                  fileName={uploadingItem.file?.name ?? 'N/A'}
                  progress={uploadingItem.progress}
                />
              ))}
            </div>
          )}
          <div className="attachments">
            {mediaResponse.data?.result.map((mediaItem) => (
              <Attachment
                key={mediaItem.key}
                mediaItem={mediaItem}
                onUpdate={mediaResponse.mutate}
              />
            ))}
          </div>
          <input ref={inputRef} type="file" {...getInputProps} />
          <div className="file-drop-overlay">Drop file here</div>
        </div>
      </div>
    </div>
  )
  // const showSkeleton: boolean = tradeItem.tradeNote?.mediaCount > 0 && mediaResponse.isLoading
  // return <MediaSection mediaItems={mediaResponse.data?.result ?? null} showSkeleton={showSkeleton} />
}

type AttachmentLoaderProps = { fileName: string; progress?: number | null }
export function AttachmentLoader({ fileName, progress }: AttachmentLoaderProps) {
  return (
    <div className="AttachmentLoader">
      <div className="text">
        <div className="file-name">{fileName}</div>
        <div className="file-status">
          Uploading... {progress != null && Math.round(progress) + '%'}
        </div>
      </div>
      <div className="progress">
        <div
          className="progress-bar"
          style={{ width: progress != null ? progress + '%' : 0 }}
        ></div>
      </div>
    </div>
  )
}

type ImageItemProps = { mediaItem: MediaItem; onUpdate: () => Promise<unknown> }
export function Attachment({ mediaItem, onUpdate }: ImageItemProps) {
  const previewModal = useModal()
  const deleteModal = useModal()
  const thumbnailImgRef = useRef<HTMLImageElement>(null)
  const isThumbnailLoading = useTrackImgLoading(thumbnailImgRef)
  const { tradeItem } = useContext(TradeInfoModalContext)

  const { callAndReport, isLoading } = useCallAndReport()

  const openDeleteModal = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.stopPropagation()
    deleteModal.open()
  }

  const deleteMedia = async () => {
    await callAndReport(
      async () => {
        if (!tradeItem.note) throw new Error('Cannot delete media without a trade note')
        await deleteMediaForNote(tradeItem.note.id, mediaItem.key)
        await onUpdate()
      },
      { DEFAULT_ERR: 'Failed to delete image', OK: 'Image removed' }
    )
  }

  return (
    <>
      <div
        className={classNames('Attachment', isThumbnailLoading && 'loading')}
        onClick={() => previewModal.open()}
      >
        <SkeletonBlock className="thumbnail-skeleton" />
        <Button square size="small" className="delete" onClick={(ev) => openDeleteModal(ev)}>
          <Icon name="Trash" />
        </Button>
        <div className="hover-card">
          <Icon name="ZoomIn" width="24px" height="24px" />
        </div>
        <img
          ref={thumbnailImgRef}
          src={mediaItem.thumbnailSignedUrl}
          alt="thumbnail"
          className="thumbnail"
        />
      </div>
      <Modal controller={deleteModal}>
        <ModalLayout>
          <ModalLayout.Heading>Delete attachment</ModalLayout.Heading>
          <ModalLayout.Description>
            Are you sure you want to delete this attachment?
          </ModalLayout.Description>
          <ModalLayout.Buttons>
            <Button onClick={() => deleteModal.close()}>Cancel</Button>
            <Button color="error" onClick={deleteMedia} isLoading={isLoading}>
              Delete
            </Button>
          </ModalLayout.Buttons>
        </ModalLayout>
      </Modal>
      <TradeInfoNoteImagePreviewModal controller={previewModal} mediaItem={mediaItem} />
    </>
  )
}

type TradeInfoNoteImagePreviewModalProps = { controller: ModalController; mediaItem: MediaItem }
function TradeInfoNoteImagePreviewModal({
  controller,
  mediaItem,
}: TradeInfoNoteImagePreviewModalProps) {
  return (
    <Modal controller={controller}>
      <div className="TradeNotePreviewImageItemModal">
        <img src={mediaItem.thumbnailSignedUrl} alt="thumbnail" className="thumbnail" />
        <img src={mediaItem.signedUrl} className="full" alt="Full" />
        <Button
          className="close-button"
          square
          size="large"
          appearance="menu-item"
          onClick={() => controller.close()}
        >
          <Icon name="Cross" width="24px" height="24px" />
        </Button>
      </div>
    </Modal>
  )
}
