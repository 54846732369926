import { useUserInfo } from 'core/api/common'
import { useJournalsAll } from 'core/hooks/useJournalsAll'
import { TradeItem } from 'core/types'
import { durationToHumanReadable } from 'core/utils'
import { RefObject, useCallback, useEffect, useMemo, useState } from 'react'

export function useIsNoteEditable(tradeItem: TradeItem | null) {
  const userInfo = useUserInfo()
  const journalsAll = useJournalsAll()
  const notesJournal = useMemo(() => {
    if (!journalsAll.data) return null
    return journalsAll.data.find((j) => j.id === tradeItem?.journalId) ?? null
  }, [journalsAll.data, tradeItem])
  const isNoteFormActive = useMemo(() => {
    if (!userInfo.data || !notesJournal) return false
    return userInfo.data.userEmail === notesJournal.owner.userEmail
  }, [userInfo.data, notesJournal])
  return isNoteFormActive
}

export function useTableInfoGridData(selectedTrade: TradeItem | null) {
  return useMemo(() => {
    const dateEntry = new Date(selectedTrade?.timeEntry ?? 0)
    const dateExit = new Date(selectedTrade?.timeExit ?? 0)
    const durationText =
      selectedTrade?.timeEntry && selectedTrade?.timeExit
        ? durationToHumanReadable(Math.abs(dateExit.getTime() - dateEntry.getTime()))
        : 'N/A'

    return {
      dateEntry,
      dateExit,
      durationText,
    }
  }, [selectedTrade])
}

export function useTrackImgLoading(ref: RefObject<HTMLImageElement>) {
  const [isLoading, setIsLoading] = useState(false)

  const handleLoad = useCallback(() => setIsLoading(false), [])
  const handleError = useCallback(() => setIsLoading(false), [])

  useEffect(() => {
    const img = ref.current
    if (!img) return
    if (img.complete) return setIsLoading(false)

    setIsLoading(true)
    img.addEventListener('load', handleLoad)
    img.addEventListener('error', handleError)

    return () => {
      if (!img) return
      img.removeEventListener('load', handleLoad)
      img.removeEventListener('error', handleError)
    }
  }, [ref, handleLoad, handleError])

  return isLoading
}
