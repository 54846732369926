import { createContext } from 'react'
import { TradesTableSectionContextData } from './models'
import { INITIAL_SWR_DATA } from 'core/utils'

export const TradesTableTabContext = createContext<TradesTableSectionContextData>({
  selectedTradeId: null,
  tradesResponse: INITIAL_SWR_DATA,
  selectedTrade: null,
  isColumSelectedMap: {
    commission: false,
    fills: false,
    instrumentAlias: false,
    isBuy: false,
    mae: false,
    mfe: false,
    maxSize: false,
    postMae: false,
    postMfe: false,
    priceEntry: false,
    priceExit: false,
    profit: false,
    timeEntry: false,
    timeExit: false,
  },
  pagingSortingParams: { page: 0, size: 0, sort: 'timeEntry', order: 'desc' },
  paginationPage: 0,
  setPaginationPage: () => {},
  sortParams: { sort: 'timeEntry', order: 'desc' },
  setSortParams: () => {},
  columns: [],
  setColumnsKeys: () => {},
  setSelectedTradeId: () => {},

  openAddNoteModal: () => {},
  openTradeInfoModal: () => {},
  openMoveTradeToModal: () => Promise.resolve(void 0),
  openConfirmDeleteTradeModal: () => {},
  openTableConfigModal: () => {},
})
