import { ChartSection } from './sections/ChartSection/ChartSection'
import './DashboardTab.scss'
import { StatisticsSection } from './sections/StatisticsSection/StatisticsSection'
import WidgetsSection from './sections/WidgetsSection'

export function DashboardTab() {
  return (
    <div className="Dashboard">
      {/* <Filters /> */}
      <StatisticsSection />
      <ChartSection label="P&L history" />
      <WidgetsSection />
      {/* <TradesTableSection /> */}
    </div>
  )
}
